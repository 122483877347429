import { Avatar, Box, Button, Card, Container, Divider, IconButton, Link, Stack, SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Seo } from "components/seo";
import { usePageView } from "hooks/use-page-view";
import type { Page as PageType } from "types/page";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { useAuth } from "hooks/use-auth";
import { GitPush } from "types/integrations/git";
import { ArrowRightIcon } from "@mui/x-date-pickers/icons";
import { RouterLink } from "components/router-link";
import { paths } from "paths";
import { blockchainExplorerLink } from "utils/link-generation";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PushStatus from "components/integrations/push-status";


const GitIntegrationPage: PageType = () => {
    usePageView();
    const auth = useAuth();
    const { token } = auth;
    const [pushes, setPushes] = useState<GitPush[]>([]);
    const peerdwebApiBaseUrl = process.env.REACT_APP_PEERDWEB_API_BASE_URL as string;

    const socket = io(peerdwebApiBaseUrl, {
        transports: ["websocket"],
        auth: {
            token
        },
        path: "/socket",
    });

    const navigateToBlockchainExplorer = async (fileHash: string) => {
        const peerdwebService = new PeerdwebService();
        const validateResponse = await peerdwebService.validateAssetHash(fileHash);

        if (!validateResponse || validateResponse.status !== 200) {
            throw new Error(`Failed to validate transaction: ${validateResponse?.status}`);
        }

        const transaction = validateResponse.data;

        if (!transaction) {
            throw new Error(`Failed to fetch transaction: ${fileHash}`);
        }

        window.open(blockchainExplorerLink(transaction.explorerUrl, transaction.txHash), "_blank");
    }

    useEffect(() => {
        const peerdwebService = new PeerdwebService();
        const organisationId = auth.organisation?._id;

        if (!organisationId) {
            return;
        }

        (async () => {
            const pushesResponse = await peerdwebService.getPushesByOrganisationId(organisationId);

            if (!pushesResponse || pushesResponse.status !== 200) {
                throw new Error(`Failed to fetch pushes: ${pushesResponse?.status}`);
            }

            setPushes(pushesResponse.data);
        })();
    }, [auth.organisation?._id]);

    useEffect(() => {
        socket.connect();

        socket.on("push-created", (push: GitPush) => {
            setPushes((prevPushes) => [push, ...prevPushes]);
        });

        socket.on("push-updated", (push: GitPush) => {
            setPushes((prevPushes) => {
                const index = prevPushes.findIndex((p) => p.latestSha === push.latestSha);

                if (index === -1) {
                    return prevPushes;
                }

                const newPushes = [...prevPushes];
                newPushes[index] = push;

                return newPushes;
            });
        });

        socket.on("connect", () => {
            // TODO: display a toast or something
        });

        socket.on("disconnect", () => {
            // TODO: display a toast or something
        });

        socket.on("status-error", (error) => {
            // TODO: display a toast or something
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <>
            <Seo title="Peerdweb: Integrations" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Stack spacing={1}>
                            <Typography variant="h4">Git Integration</Typography>
                        </Stack>

                        <Divider />

                        <Typography variant="h5">Pushes</Typography>

                        {pushes.map((push) => (
                            <Card key={push._id}>
                                <Table sx={{ minWidth: 600 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="25%">
                                                <Stack
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={2}
                                                    sx={{
                                                        display: 'inline-flex',
                                                        textDecoration: 'none',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    <Link href={push.sender.html_url} target="_blank" rel="noopener noreferrer">
                                                        <Avatar
                                                            sx={{
                                                                height: 42,
                                                                width: 42,
                                                            }}
                                                            src={push.sender.avatar_url}
                                                            title={push.sender.login}
                                                        >
                                                        </Avatar>
                                                    </Link>
                                                    <Stack>
                                                        <Typography
                                                            color="text.secondary"
                                                            variant="subtitle1"
                                                        >
                                                            {push.head_commit.message}
                                                        </Typography>
                                                        <Link href={push.sender.html_url} target="_blank" rel="noopener noreferrer" variant="body2">
                                                            {push.sender.login}
                                                        </Link>
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2">Created</Typography>
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    {new Date(push.createdAt).toLocaleString(undefined, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2">Head Commit</Typography>
                                                <Link
                                                    href={push.head_commit.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    variant="caption">
                                                    {push.head_commit.id?.substring(0, 7)}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">
                                                <PushStatus status={push.processingStatus.status} />
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    {push.processingStatus.message}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    component={RouterLink}
                                                    href={paths.integrations.git}   //TODO: Update to push details page
                                                >
                                                    <SvgIcon>
                                                        <ArrowRightIcon />
                                                    </SvgIcon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography variant="h6" sx={{ pb: 2 }}>Changed Files</Typography>

                                                <Table sx={{ backgroundColor: "#f7f7f7" }}>
                                                    <TableBody>
                                                        {push.changedFiles.map((file) => (
                                                            <TableRow key={file.filename}>
                                                                <TableCell>{file.filename}</TableCell>
                                                                <TableCell>
                                                                    <PushStatus status={file.processingStatus.status} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography
                                                                        color="text.secondary"
                                                                        variant="caption"
                                                                    >
                                                                        {file.processingStatus.message}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Link component={Button} onClick={() => navigateToBlockchainExplorer(file.hash)} sx={{ alignItems: "center", display: "flex" }}>
                                                                        View on Blockchain Explorer <OpenInNewIcon sx={{ ml: 1 }} />
                                                                    </Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                        ))}
                    </Stack>
                </Container>
            </Box >
        </>
    );
};

export default GitIntegrationPage;