import { Box, Button, Container, Divider, Stack, Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import type { Page as PageType } from 'types/page';
import { Seo } from 'components/seo';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { useAuth } from 'hooks/use-auth';
import { useRouter } from 'hooks/use-router';
import { useParams } from 'react-router-dom';
import { paths } from 'paths';
import { useLogger } from 'hooks/use-logger';
import { Project } from 'types/project/project';
import { ViewProject } from 'components/projects/view-project/view-project';
import { UpdateProject } from 'components/projects/view-project/update-project';
import getDebugData from 'utils/cherry-pick-seq-data';

const tabs = [
    { label: "View Project", value: "view", ownerOnly: false },
    { label: "Update Project", value: "update", ownerOnly: true },
    // { label: "Smart Access Control", value: "access", ownerOnly: true },
    // { label: "Activity Log", value: "log", ownerOnly: true },
];

const ViewProjectPage: PageType = () => {
    const auth = useAuth();
    const { peerdwebUser } = auth;
    const peerdwebService = new PeerdwebService();
    const { id } = useParams();
    const router = useRouter();
    const [project, setProject] = useState<Project>();
    const [currentTab, setCurrentTab] = useState<string>("view");
    const [userHasAccess, setUserHasAccess] = useState<boolean>(false);
    const [userIsOwner, setUserIsOwner] = useState<boolean>(false);
    const [log] = useLogger();

    if (id == null)
        throw new Error("Project id is null");

    async function getProject() {
        try {
            const projectResponse = await peerdwebService.getProjectById(id as string, peerdwebUser?._id as string)
            setProject(projectResponse.data);
            if (projectResponse.data.owner._id === peerdwebUser?._id)
                setUserIsOwner(true);
            setUserHasAccess(true);
        }
        catch (err: any) {
            if (err.response.status === 401) {
                setUserHasAccess(false);
                log("View Project // access denied", "Error", { projectId: id, ...getDebugData(auth) });
                return;
            }
            if (err.response.status === 404) {
                setUserHasAccess(false);
                log("View Project // project not found", "Error", { projectId: id, ...getDebugData(auth) });
                return;
            }
            console.error(err);
        }
    }

    useEffect(() => {
        getProject();
    }, []);

    const handleTabsChange = useCallback((event: ChangeEvent<any>, value: string): void => {
        setCurrentTab(value);
    }, []);

    useEffect(() => { log("Page ___ View Project", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Seo title="Protected: Project View" />
            <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
                <Container maxWidth='xl'>

                    {!userHasAccess && <>
                        Access denied.<br />
                        You do not have access to view this project. Please contact the project owner.
                    </>
                    }

                    {userHasAccess && project &&
                        <>
                            <Stack spacing={3} sx={{ mb: 3 }}>
                                <div>
                                    <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                                        {tabs.map((tab) => (
                                            !tab.ownerOnly &&
                                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                                        ))}
                                        {tabs.map((tab) => (
                                            tab.ownerOnly && userIsOwner &&
                                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                                        ))}
                                    </Tabs>
                                    <Divider />
                                </div>
                            </Stack>


                            {currentTab === "view" &&
                                <ViewProject project={project} />
                            }


                            {currentTab === "update" && project &&
                                <UpdateProject project={project} />
                            }

                            {/* {currentTab === "access" &&
                                <SmartAccess asset={project} />
                            }


                            {currentTab === "log" &&
                                <ActivityLog asset={project} />
                            } */}

                        </>
                    }

                    <Divider sx={{ mt: 8, mb: 4, borderColor: '#6C737F' }}></Divider>

                    <Box sx={{ p: 1 }}>
                        <Button variant="outlined" onClick={() => router.push(paths.projects.list)}>
                            Back to Projects
                        </Button>
                    </Box>

                </Container>
            </Box >
        </>
    );
};

export default ViewProjectPage;