import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import type { Page as PageType } from 'types/page';
import { Seo } from 'components/seo';
import { Asset } from "types/assets/asset";
import { useAuth } from 'hooks/use-auth';
import DownloadZippedAssetFile from 'components/assets/new-asset/download-zipped-asset-file';
import { useLogger } from 'hooks/use-logger';
import { NewAsset } from 'components/assets/new-asset/new-asset';
import getDebugData from 'utils/cherry-pick-seq-data';

const NewAssetPage: PageType = () => {
    const [downloadUrl, setDownloadUrl] = useState<string>();
    const [asset, setAsset] = useState<Asset>();
    const auth = useAuth();
    const { getSubscriptionLevel, organisation } = auth;
    const [log] = useLogger();
    const isEnterprise = getSubscriptionLevel() === 'ENTERPRISE';

    useEffect(() => { log("Page ___ New Asset", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Seo title="Protect: New Asset" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth='xl'>
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        {!downloadUrl &&
                            <>
                                <Stack direction="row" justifyContent="space-between" spacing={4}>
                                    <Stack spacing={1}>
                                        <Typography variant="h4">
                                            New Asset
                                        </Typography>
                                    </Stack>
                                    {!isEnterprise &&
                                        <Stack alignItems="center" direction="row" spacing={3}>
                                            <Typography variant="subtitle1">
                                                Credits: <strong>{organisation?.credits ?? "0"}</strong>
                                            </Typography>
                                        </Stack>
                                    }
                                </Stack>
                                <Divider />
                            </>
                        }

                        {!downloadUrl &&
                            <>
                                <NewAsset setAsset={setAsset} setDownloadUrl={setDownloadUrl} />
                                {!isEnterprise &&
                                    <Stack direction={'row'} spacing={3} sx={{ alignItems: 'flex-end' }}>
                                        <Typography variant="h5">
                                            Cost: 1 Credit
                                        </Typography>
                                        <Typography color="text.secondary" variant="body2">
                                            Current balance: <strong>{organisation?.credits ?? "0"}</strong>
                                        </Typography>
                                    </Stack>
                                }
                            </>
                        }


                        {downloadUrl &&
                            <DownloadZippedAssetFile assetName={asset?.headline} downloadObjectUrl={downloadUrl} />
                        }


                    </Stack>
                </Container>

            </Box >
        </>
    );
};

export default NewAssetPage;