import { FC } from 'react';
import { useEffect } from 'react';
import { Alert } from '@mui/material';
import { ProjectListSearchCreate } from './project-list-search-create';
import { ProjectListTable } from './project-list-table';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { Stack } from '@mui/system';
import { useProjectsSearch, useProjectsStore } from 'hooks/projects/useProjectsStore';
import getDebugData from 'utils/cherry-pick-seq-data';

interface IProps {
    projectSelectionChanged: (id: string, name: string) => void;
    selectedProjectId: string;
    selectedProjectName: string;
}

export const ViewProjectList: FC<IProps> = ({ projectSelectionChanged, selectedProjectId, selectedProjectName }) => {
    const auth = useAuth();
    const { organisation } = auth;
    const projectsSearch = useProjectsSearch(organisation?._id ?? '');
    const projectsStore = useProjectsStore(projectsSearch.state);
    const [log] = useLogger();

    useEffect(() => { log("Projects ___ List", "Debug", getDebugData(auth)); }, []);

    return (
        <Stack spacing={2} sx={{ minHeight: 550 }}>
            <ProjectListSearchCreate onChange={projectsSearch.handleSearchChange} onCreate={projectsStore.handleCreateProject} />
            {projectsStore.errorMessage &&
                <Alert variant="filled" severity="error">
                    {projectsStore.errorMessage}
                </Alert>
            }
            <ProjectListTable
                onPageChange={projectsSearch.handlePageChange}
                onRowsPerPageChange={projectsSearch.handleRowsPerPageChange}
                page={projectsSearch.state.page}
                items={projectsStore.projects}
                count={projectsStore.projectsCount}
                pageSize={projectsSearch.state.pageSize}
                projectSelectionChanged={projectSelectionChanged}
                selectedProjectId={selectedProjectId}
                selectedProjectName={selectedProjectName}
            />
        </Stack>
    );
};

