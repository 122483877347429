import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import Download from '@untitled-ui/icons-react/build/esm/Download01';
import { useRouter } from "hooks/use-router";
import { paths } from "paths";
import { useLogger } from "hooks/use-logger";
import { useAuth } from "hooks/use-auth";
import getDebugData from "utils/cherry-pick-seq-data";


interface IProps {
    assetName?: string;
    downloadObjectUrl: string;
}


const DownloadZippedAssetFile: FC<IProps> = (props: any) => {
    const router = useRouter();
    const [downloadComplete, setDownloadComplete] = useState(false);
    const auth = useAuth();
    const { getSubscriptionLevel, organisation } = auth;
    const [log] = useLogger();
    const isEnterprise = getSubscriptionLevel() === 'ENTERPRISE';

    const generateAssetFilename = (assetName?: string) => {
        if (!assetName || assetName === '')
            return `asset.${process.env.REACT_APP_PROTECTED_FILE_EXT as string}`;

        const filename = assetName.replace(/[^a-z0-9]/gi, '-').toLowerCase().trim();
        return `${filename}.${process.env.REACT_APP_PROTECTED_FILE_EXT as string}`;
    }

    useEffect(() => {
        isEnterprise && organisation?.aws != null && setDownloadComplete(true);
    }, [isEnterprise, organisation]);

    const handleDownload = async () => {
        if (props.downloadObjectUrl) {
            const link = document.createElement('a');
            link.href = props.downloadObjectUrl;
            link.setAttribute('download', generateAssetFilename(props.assetName));
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDownloadComplete(true);
            log("Zip downloaded", "Information", { assetName: props.assetName, ...getDebugData(auth) });
        }
    }

    const handleFinish = () => {
        log("New asset flow finished", "Information", getDebugData(auth));
        router.push(paths.assets.list);
    }

    const DownloadButton = () => {
        return (
            <Button startIcon={(<SvgIcon><Download /></SvgIcon>)} variant="contained" style={{ maxWidth: 200 }} onClick={handleDownload}>
                Download Asset ID
            </Button>
        );
    };

    return (
        <>
            <div>
                <Typography variant="h4" marginBottom={2}>
                    Asset Created
                </Typography>

                <Typography variant="body1" marginBottom={2}>
                    <p>
                        {(isEnterprise && organisation?.aws != null) &&
                            <>
                                Your asset has been safeguarded and is securely stored.
                            </>
                        }
                        {(!isEnterprise || organisation?.aws == null) &&
                            <>
                                Your asset has been safeguarded, and your unique ID file is now ready for download.
                                Please make sure to securely save this file before proceeding.
                            </>
                        }
                    </p>
                </Typography>
            </div>

            <Stack spacing={1} marginTop={5} direction={'row'}>
                {(!isEnterprise || organisation?.aws == null) &&
                    <DownloadButton />
                }
                <Button endIcon={(<SvgIcon><ArrowRightIcon /></SvgIcon>)} type="submit" variant="contained" style={{ maxWidth: 200 }} disabled={!downloadComplete} onClick={handleFinish}>
                    Finish
                </Button>
            </Stack>
        </>
    );

};

export default DownloadZippedAssetFile;