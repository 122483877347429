import { Backdrop, Box, Button, Divider, Link, Paper, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { MandatoryFields } from '../asset-fields/mandatory-fields';
import { NewAssetSelectFile } from './select-file';
import { ErrorMessage, Form, Formik } from 'formik';
import { NewAssetValues, NewAssetRequest, AssetFile, Asset, DisplayAdditionalField } from "types/assets/asset";
import { createAssetValidationSchema } from 'validation/assets/create-asset-validation-schema';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { toast } from 'react-hot-toast';
import { useAuth } from 'hooks/use-auth';
import FilesZipper from 'core/files-zipper';
import Hasher from 'core/hasher';
import { RouterLink } from 'components/router-link';
import { paths } from 'paths';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { useLogger } from 'hooks/use-logger';
import AdditionalFieldsDialog from 'components/assets/asset-fields/additional-fields-dialog';
import ProjectDialog from 'components/assets/asset-fields/project-dialog';
import { AssetFields } from '../asset-fields/asset-fields';
import { Organisation } from 'types/organisation/organisation';
import { deepCopy } from 'utils/deep-copy';
import { AxiosError } from 'axios';
import AWS from 'aws-sdk';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import { PeerdwebColours } from 'theme/peerdweb-colours';
import HashLoader from 'react-spinners/HashLoader';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import getDebugData from 'utils/cherry-pick-seq-data';
import { Logger } from 'seq-logging';

const initialValues: NewAssetValues = {
    titleHeadline: '',
    abstract: '',
    files: [],
    zipHash: '',
};

interface IProps {
    setDownloadUrl: (url: string) => void;
    setAsset: (asset: Asset) => void;
}

export const NewAsset: FC<IProps> = ({ setAsset, setDownloadUrl }) => {
    const auth = useAuth();
    const { organisation, peerdwebUser } = auth;
    const [showPleaseWait, setShowPleaseWait] = useState(false);
    const [mintComplete, setMintComplete] = useState(false);
    const [zipComplete, setZipComplete] = useState(false);
    const [storageComplete, setStorageComplete] = useState(false);
    const [zipFilePercentComplete, setZipFilePercentComplete] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const peerdwebService = new PeerdwebService();
    const [log] = useLogger();
    const { getSubscriptionLevel } = auth;
    const isEnterprise = getSubscriptionLevel() === 'ENTERPRISE';
    const [displayFields, setDisplayFields] = useState<DisplayAdditionalField>({
        categorisation: false,
        tags: false,
        location: false
    });
    const [project, setProject] = useState<{ id: string | null, name: string | null }>({ id: null, name: null });
    const [categorisation, setCategorisation] = useState<{ subject: string, format: string, product: string }>({ subject: '', format: '', product: '' });
    const [location, setLocation] = useState<string>('');
    const [status, setStatus] = useState<string>('Active');
    const [tags, setTags] = useState<string[]>([]);
    const [assetValue, setAssetValue] = useState<string>('');
    const [secureStorageFileLocation, setSecureStorageFileLocation] = useState<string>('');

    useEffect(() => {
        console.log(zipFilePercentComplete);
    }, [zipFilePercentComplete]);


    // TODO extract this out to Store or somewhere
    const createAsset = async (newAssetValues: NewAssetValues, filename: string) => {
        if (peerdwebUser == null || peerdwebUser._id == null) {
            log("User is not logged in", "Warning", getDebugData(auth));
            throw new Error("User is not logged in");
        }


        if (organisation == null || organisation._id == null) {
            log("No organisation selected", "Warning", getDebugData(auth));
            throw new Error("No organisation selected");
        }

        let assetFiles: AssetFile[] = [];

        for (const file of newAssetValues.files) {
            assetFiles.push({
                filename: file.name,
                hash: await Hasher.generate(file),
            });
        }

        var newAssetRequest: NewAssetRequest = {
            headline: newAssetValues.titleHeadline,
            abstract: newAssetValues.abstract,
            files: assetFiles,
            zipHash: newAssetValues.zipHash,
            displayFields: displayFields,
            owner: peerdwebUser._id,
            secureStorageFileLocation: filename,
            status: status,
            assetValue: assetValue,
            organisation: organisation._id,
        };

        if (project.id) {
            newAssetRequest.project = project.id;
        }

        if (displayFields.categorisation) {
            newAssetRequest.subject = categorisation.subject;
            newAssetRequest.format = categorisation.format;
            newAssetRequest.product = categorisation.product;
        }

        if (displayFields.location) {
            newAssetRequest.location = location
        }

        if (displayFields.tags) {
            newAssetRequest.tags = tags;
        }

        try {
            const asset = await peerdwebService.createAsset(newAssetRequest);
            setAsset(asset.data);
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                if (axiosError.response.status === 500 && typeof axiosError.response.data === 'object' && axiosError.response.data !== null && 'error' in axiosError.response.data && typeof axiosError.response.data.error === 'string') {
                    log("Error creating asset", "Error", { newAssetValues, filename, error: axiosError.response.data.error, ...getDebugData(auth) });
                    throw new Error(axiosError.response.data.error);
                } else {
                    log("Error creating asset", "Error", { newAssetValues, filename, error, ...getDebugData(auth) });
                    throw new Error("An error occurred creating asset.");
                }
            }
            else {
                log("Error creating asset", "Error", { newAssetValues, filename, error, ...getDebugData(auth) });
                throw new Error("An error occurred creating asset.");
            }
        }
    }

    const zipFiles = async (files: File[]): Promise<Blob> => {
        const filesZipper = new FilesZipper(files, (percent) =>
            setZipFilePercentComplete(percent)
        );
        return await filesZipper.zipFiles();
    }

    const uploadFileToS3 = async (file: any): Promise<string> => {
        // TODO this whole AWS integration needs reworking before we actually having a paying customer
        // this gets us across the line for demoing purposes
        // migrate to using the AWS SDK v3 https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/welcome.html


        // current version: https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/welcome.html

        if (!organisation?.aws) {
            log("Organisation AWS configuration is missing", "Error", getDebugData(auth));
            throw new Error("Organisation AWS configuration is missing");
        }

        const S3_BUCKET = organisation.aws.bucket;
        const REGION = organisation.aws.region;

        AWS.config.update({
            accessKeyId: organisation.aws.accessKeyId,
            secretAccessKey: organisation.aws.secretAccessKey,
        });
        AWS.config.logger = console;


        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: (await Hasher.generate(file)) + '/' + (new Date).getTime().toString() + '/' + file.name,
            Body: file,
        };

        const upload = s3.upload(params).on("httpUploadProgress", (evt) => {
            console.log("Uploading " + parseInt(((evt.loaded * 100) / evt.total).toString()) + "%");
        });

        try {
            const response = await upload.promise();
            console.log("File uploaded successfully.", response);
            log("File uploaded to S3", "Information", { filename: file.name, location: response.Location, ...getDebugData(auth) });
            setSecureStorageFileLocation(response.Location);
            return response.Location;
        } catch (error) {
            log("Error uploading file to S3", "Error", { filename: file.name, error, ...getDebugData(auth) });
            console.error("Error uploading file to S3:", error);
            throw error;
        }
    };

    const handleSubmit = async (values: NewAssetValues) => {
        setShowPleaseWait(true);
        setZipComplete(false);
        setMintComplete(false);
        setStorageComplete(false);

        try {

            // step 1 - zip files
            const zipBlob = await zipFiles(values.files);
            values.zipHash = await Hasher.generate(zipBlob);
            setZipComplete(true);


            // step 2 - upload to secure storage
            var filename = '';
            if (isEnterprise && organisation?.aws) {
                filename = await uploadFileToS3(values.files[0]);
                setStorageComplete(true);
            }


            // step 3 - create asset; upload to blockchain and create in Mongo
            await createAsset(values, filename);
            log("Asset created", "Information", { titleHeadline: values.titleHeadline, filename, ...getDebugData(auth) });
            setMintComplete(true);


            // step 4 - spend a credit
            let updated = deepCopy(organisation) as Organisation;
            organisation!.credits = organisation!.credits - 1;
            updated.credits = updated.credits - 1;
            await peerdwebService.updateOrganisation(updated);


            // step 5 - download the zip file, if not enterprise
            const downloadUrl = URL.createObjectURL(zipBlob);
            setDownloadUrl(downloadUrl);

            toast.success("Asset created successfully", { icon: "✅" });
        } catch (error: any) {
            log("Error creating asset", "Error", { error, ...getDebugData(auth) });
            toast.error("Asset creation failed: " + error.message);
            setErrorMessage("❗Asset creation failed, please try again.  Message: " + error.message);
        }
        finally {
            HideWhenComplete();
            setZipFilePercentComplete(0);
        }
    };

    const HideWhenComplete = () => {
        setTimeout(() => {
            setShowPleaseWait(false);
        }, 2000);
    }

    useEffect(() => { log("New Asset", "Debug", getDebugData(auth)); }, []);

    const handleFieldsToDisplay = useCallback((key: string, value: boolean): void => {
        const updatedDisplayFields = {
            ...displayFields,
            [key]: value,
        };
        setDisplayFields(updatedDisplayFields);
    }, [displayFields]);

    const handleProjectSelected = useCallback((projectId: string, name: string): void => {
        setProject({ id: projectId, name });
    }, []);

    const handleRemoveProject = useCallback(() => {
        setProject({ id: null, name: null });
    }, []);

    const handleCategorisationChange = useCallback((categorisation: { subject: string, format: string, product: string }) => {
        setCategorisation({ subject: categorisation.subject, format: categorisation.format, product: categorisation.product });
    }, []);

    const handleLocationChange = useCallback((location: string) => {
        setLocation(location);
    }, []);

    const handleStatusChange = useCallback((status: string) => {
        setStatus(status);
    }, []);

    const handleAssetValueChange = useCallback((assetValue: string) => {
        setAssetValue(assetValue);
    }, [assetValue, setAssetValue]);

    const handleTagsChange = useCallback((tags: string[]) => {
        setTags(tags);
    }, []);

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={createAssetValidationSchema} onSubmit={handleSubmit}>
                <Form>

                    {organisation?.credits! === 0 &&
                        <Box sx={{ p: 4, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                            <AccountBalanceWalletTwoToneIcon sx={{ display: "inline-flex", color: "#f08383", alignItems: "center", width: 48, height: 48, mr: 3, mb: 3 }} />
                            <Box sx={{ mr: 3, mb: 3 }}>
                                You currently have 0 Credits. To continue protecting your assets, please add <Link component={RouterLink} href={paths.organisation}>Credits</Link> to your account.<br />We value your trust and are here to help should you have any questions - <Link id='mailto-link' href="mailto:support@peerdweb.io">support@peerdweb.io</Link>
                            </Box>
                            <Button endIcon={(<AccountBalanceWalletTwoToneIcon />)} type="button" variant="contained" style={{ minWidth: 240, maxWidth: 240, maxHeight: 45 }} href={paths.organisation}>
                                Add Credits
                            </Button>
                        </Box>
                    }

                    <MandatoryFields />

                    <Stack spacing={3} sx={{ m: 3 }}>
                        <Stack direction="row" spacing={4}>
                            <AdditionalFieldsDialog displayFields={displayFields} updateFieldsToDisplay={handleFieldsToDisplay} />
                            <ProjectDialog projectSelected={handleProjectSelected} />
                        </Stack>
                    </Stack>

                    <AssetFields
                        displayFields={displayFields}
                        projectId={project.id ?? ''}
                        projectName={project.name ?? ''}
                        handleRemoveProject={handleRemoveProject}
                        handleCategorisationChange={handleCategorisationChange}
                        handleLocationChange={handleLocationChange}
                        handleStatusChange={handleStatusChange}
                        handleTagsChange={handleTagsChange}
                        categorisation={categorisation}
                        location={location}
                        status={status}
                        tags={tags}
                        assetValue={assetValue}
                        handleAssetValueChange={handleAssetValueChange}
                    />

                    <Divider sx={{ my: 4 }} />
                    <Stack>
                        <NewAssetSelectFile isEnterprise={isEnterprise} />

                        {
                            isEnterprise && organisation?.aws &&
                            <>
                                <Stack sx={{ mt: 4 }} direction={'row'}>
                                    {/* <ShieldIcon sx={{ display: "inline-flex", color: "#f08383", alignItems: "center", width: 32, height: 32, mr: 3, mb: 3 }} /> */}
                                    <ShieldTwoToneIcon sx={{ color: PeerdwebColours.Navy1, mr: 1 }} />
                                    <Box>
                                        Your file will be uploaded to secure storage provided by organisation: <strong>{organisation.name}</strong>.
                                    </Box>

                                </Stack>
                            </>
                        }

                    </Stack>


                    <Stack spacing={3} sx={{ my: 8 }}>
                        {showPleaseWait &&
                            <>
                            </>
                        }
                        <Button endIcon={(<GppGoodIcon />)} type="submit" variant="contained" style={{ maxWidth: 300 }} disabled={showPleaseWait || organisation?.credits === 0}>
                            Protect Asset
                        </Button>
                        <ErrorMessage name="titleHeadline" render={msg => <div style={{ color: '#F04438', paddingLeft: '15px' }}>{msg}</div>} />
                        <ErrorMessage name="files" render={msg => <div style={{ color: '#F04438', paddingLeft: '15px' }}>{msg}</div>} />

                        {errorMessage &&
                            <Typography variant="body1" sx={{ color: '#F04438' }}>{errorMessage}</Typography>
                        }

                    </Stack>

                    <Backdrop open={showPleaseWait}>

                        <Paper sx={{ p: 4, width: 380, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <HashLoader size={32} color={PeerdwebColours.NeonBlue} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="body1" sx={{ my: 2 }}>
                                    Please wait while we protect your asset.
                                </Typography>
                            </Box>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="body2" marginBottom={2}>
                                This process may take up to 2 minutes, please <strong>do not refresh</strong> your browser.
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="body1">
                                    Process zip file:
                                </Typography>
                                {!zipComplete ? <CheckBoxOutlineBlankTwoToneIcon sx={{ color: PeerdwebColours.Navy }} /> : <CheckBoxOutlinedIcon sx={{ color: PeerdwebColours.Navy }} />}
                            </Box>
                            {isEnterprise &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="body1">
                                        {/* {storageComplete ? "Uploading file to secure storage..." : "✅ File uploaded to secure storage."} */}
                                        Upload file to secure storage:
                                    </Typography>
                                    {!storageComplete ? <CheckBoxOutlineBlankTwoToneIcon sx={{ color: PeerdwebColours.Navy }} /> : <CheckBoxOutlinedIcon sx={{ color: PeerdwebColours.Navy }} />}
                                </Box>
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="body1">
                                    Mint to blockchain:
                                </Typography>
                                {!mintComplete ? <CheckBoxOutlineBlankTwoToneIcon sx={{ color: PeerdwebColours.Navy }} /> : <CheckBoxOutlinedIcon sx={{ color: PeerdwebColours.Navy }} />}
                            </Box>
                        </Paper>
                    </Backdrop>
                </Form>
            </Formik>
        </>
    );
};
