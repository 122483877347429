import { FC, useEffect } from 'react';
import { Card, Typography } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { Project } from 'types/project/project';
import { Stack } from '@mui/system';
import { ViewAssetsEnhancedTable } from 'components/assets/asset-list/view-assets-enhanced-table';
import getDebugData from 'utils/cherry-pick-seq-data';

interface IProps {
    project: Project;
}

export const ViewProject: FC<IProps> = ({ project }) => {
    const auth = useAuth();
    const [log] = useLogger();

    useEffect(() => { log("Project ___ View ", "Debug", { project, ...getDebugData(auth) }); }, []);

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
                <Typography variant="h5" sx={{ mb: 2, color: '#6C737F' }}>
                    Projects //
                </Typography>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    {project.name}
                </Typography>
            </Stack>
            {project.assets &&
                <Card>
                    <ViewAssetsEnhancedTable assets={project.assets} onFiltersChange={() => { }} slimMode={true} />
                </Card>
            }
        </>
    );
};

