import { Avatar, Box, Card, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, Link, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { usePageView } from 'hooks/use-page-view';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Scrollbar } from "components/scrollbar";
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { useRouter } from "hooks/use-router";
import { paths } from 'paths';
import useSmartAccessStore from 'hooks/smart-access/useSmartAccessStore';
import { SmartAccessDialog } from 'components/smart-access/smart-access-dialog';
import { SmartAccess } from 'types/smart-access/smart-access';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PeerdwebColours } from 'theme/peerdweb-colours';
import { toPrettyDateTime, toPrettyUserAccessStatus } from 'utils/pretty-pretty';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import getDebugData from 'utils/cherry-pick-seq-data';

const IncomingSmartAccessList: FC = () => {
    const auth = useAuth();
    const [log] = useLogger();
    const smartAccessStore = useSmartAccessStore();
    const router = useRouter();
    const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
    const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
    const [isRelinquishDialogOpen, setIsRelinquishDialogOpen] = useState(false);
    const [selectedSmartAccess, setSelectedSmartAccess] = useState<SmartAccess | null>(null);

    const [obligationChecked, setObligationChecked] = useState(false);
    const [actionDisabled, setActionDisabled] = useState(true);
    function handleChange(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        setObligationChecked(checked);
    }

    useEffect(() => {
        smartAccessStore.getIncomingSmartAccessList();
    }, []);

    useEffect(() => {
        // when they view a smart access accept invite, if there's obligation attached, then they have to check the checkbox to accept
        setActionDisabled(selectedSmartAccess?.obligationHtml ? true : false);
    }, [selectedSmartAccess]);

    useEffect(() => {
        setActionDisabled(!obligationChecked);
    }, [obligationChecked]);

    usePageView();

    useEffect(() => { log("Smart Access ___ Incoming List", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Card>
                <CardContent>
                    <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                        <Table sx={{ minWidth: 400 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '1px', padding: '0px !important' }}>
                                    </TableCell>
                                    <TableCell>
                                        Asset
                                    </TableCell>
                                    <TableCell>
                                        Owner
                                    </TableCell>
                                    <TableCell>
                                        Status / Date
                                    </TableCell>
                                    <TableCell>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {smartAccessStore.isReady && smartAccessStore.smartAccessList?.map((smartAccess, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ width: '1px', padding: '5px 0px 0px 3px !important' }}>
                                            {smartAccess?.status === 'accept' && <VisibilityIcon sx={{ color: PeerdwebColours.Navy1 }} />}
                                        </TableCell>
                                        <TableCell>
                                            {smartAccess?.status === 'accept' ? (
                                                <Link component={"button"} onClick={() => router.push(paths.assets.view(smartAccess?.asset._id as string))}>
                                                    {smartAccess?.asset.headline}
                                                </Link>
                                            ) : (
                                                <>
                                                    {smartAccess?.asset.headline}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <Tooltip title={smartAccess?.owner?.email}>
                                                    <Avatar src={smartAccess?.owner?.imageUrl} sx={{ height: 40, width: 40 }}>
                                                        <SvgIcon>
                                                            <User01Icon />
                                                        </SvgIcon>
                                                    </Avatar>
                                                </Tooltip>
                                                <div>
                                                    <Tooltip title={smartAccess?.owner?.email}>
                                                        <Typography variant="subtitle2">
                                                            {smartAccess?.owner?.displayName}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {toPrettyUserAccessStatus(smartAccess?.status)}<br />
                                            <Typography color="text.secondary" variant="subtitle2">
                                                {smartAccess?.createdAt && new Date(smartAccess?.createdAt).toLocaleString('en-GB')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            {smartAccess?.status === 'invite' &&
                                                <Stack spacing={1} alignItems={'flex-start'}>
                                                    <Link component={"button"} onClick={() => { setSelectedSmartAccess(smartAccess); setIsAcceptDialogOpen(true); }}>
                                                        Accept Invite
                                                    </Link>
                                                    <Link component={"button"} onClick={() => { setSelectedSmartAccess(smartAccess); setIsDeclineDialogOpen(true); }}>
                                                        Decline Invite
                                                    </Link>
                                                </Stack>
                                            }
                                            {smartAccess?.status === 'accept' &&
                                                <>
                                                    <Stack spacing={1} alignItems={'flex-start'}>
                                                        <Link component={"button"} onClick={() => { setSelectedSmartAccess(smartAccess); setIsRelinquishDialogOpen(true); }}>
                                                            Relinquish Access
                                                        </Link>
                                                    </Stack>
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </CardContent>
            </Card >

            <SmartAccessDialog
                open={isAcceptDialogOpen}
                confirmText='Accept'
                onConfirm={() => {
                    if (selectedSmartAccess) {
                        smartAccessStore.accept(selectedSmartAccess._id);
                    }
                    setIsAcceptDialogOpen(false);
                }}
                onCancel={() => {
                    setIsAcceptDialogOpen(false);
                    setSelectedSmartAccess(null);
                }}
                disabled={actionDisabled}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                    Accept Invite
                </Typography>
                <Divider />
                {
                    selectedSmartAccess?.obligationHtml &&
                    <>
                        <Typography sx={{ py: 2 }}>
                            The following obligations apply to this Smart Access:
                        </Typography>
                        <Box sx={{ px: 4, border: '1px solid #EFEFEF' }}>
                            <strong>Obligation text from {selectedSmartAccess?.owner.displayName} {toPrettyDateTime(new Date(selectedSmartAccess?.createdAt))}:</strong>
                            <div dangerouslySetInnerHTML={{ __html: selectedSmartAccess?.obligationHtml }} />
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={obligationChecked} onChange={handleChange} />}
                                    label="I accept these obligations"
                                />
                            </FormGroup>
                        </Box>
                    </>
                }
                <Typography sx={{ py: 2 }}>
                    By accepting this Smart Access invite, you will be able to view asset <strong>{selectedSmartAccess?.asset.headline}</strong>, asset owner <strong>{selectedSmartAccess?.owner.displayName}</strong> will be notified of your acceptance.
                </Typography>
            </SmartAccessDialog>

            <SmartAccessDialog
                open={isDeclineDialogOpen}
                confirmText='Decline'
                onConfirm={() => {
                    if (selectedSmartAccess) {
                        smartAccessStore.decline(selectedSmartAccess._id);
                    }
                    setIsDeclineDialogOpen(false);
                }}
                onCancel={() => {
                    setIsDeclineDialogOpen(false);
                    setSelectedSmartAccess(null);
                }}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                    Decline Invite
                </Typography>
                <Divider />
                <Typography sx={{ py: 2 }}>
                    By declining this Smart Access invite, you will not be able to view asset <strong>{selectedSmartAccess?.asset.headline}</strong>.<br /><br />
                    Asset owner, <strong>{selectedSmartAccess?.owner.displayName}</strong>, will be notified of your declination.
                </Typography>
            </SmartAccessDialog>

            <SmartAccessDialog
                open={isRelinquishDialogOpen}
                confirmText='Relinquish'
                onConfirm={() => {
                    if (selectedSmartAccess) {
                        smartAccessStore.relinquish(selectedSmartAccess._id);
                    }
                    setIsRelinquishDialogOpen(false);
                }}
                onCancel={() => {
                    setIsRelinquishDialogOpen(false);
                    setSelectedSmartAccess(null);
                }}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                    Relinquish Access
                </Typography>
                <Divider />
                <Typography sx={{ py: 2 }}>
                    By relinquishing access to this Smart Access, you will no longer be able to view asset <strong>{selectedSmartAccess?.asset.headline}</strong>.<br /><br />
                    Asset owner, <strong>{selectedSmartAccess?.owner.displayName}</strong>, will be notified of your relinquishment.
                </Typography>
            </SmartAccessDialog>

        </>
    );
};

export default IncomingSmartAccessList;
