import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import { usePageView } from 'hooks/use-page-view';
import { ManageUser } from 'components/account/manage-user';
import type { Page as PageType } from 'types/page';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { useEffect } from 'react';
import getDebugData from 'utils/cherry-pick-seq-data';

const AccountPage: PageType = () => {
    const auth = useAuth();
    const { peerdwebUser } = auth;
    const [log] = useLogger();

    usePageView();

    useEffect(() => { log("Page ___ Account", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Seo title="Dashboard: Account" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h4">
                            Account Settings
                        </Typography>
                        <Divider />
                    </Stack>
                    <ManageUser
                        imageUrl={peerdwebUser?.imageUrl ?? ''}
                        email={peerdwebUser?.email ?? ''}
                        displayName={peerdwebUser?.displayName ?? ''}
                        createdAt={peerdwebUser?.createdAt ?? ''}
                        lastLoggedInAt={peerdwebUser?.lastLoggedInAt ?? ''}
                    />
                </Container>
            </Box>
        </>
    );
};

export default AccountPage;
