import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import { usePageView } from 'hooks/use-page-view';
import type { Page as PageType } from 'types/page';
import { ViewProjects } from 'components/projects/project-list/view-projects';
import { useAuth } from 'hooks/use-auth';
import getDebugData from 'utils/cherry-pick-seq-data';
import { useEffect } from 'react';
import { useLogger } from 'hooks/use-logger';

const ProjectListPage: PageType = () => {
    const auth = useAuth();
    const { organisation } = auth;
    const [log] = useLogger();

    usePageView();

    useEffect(() => { log("Page ___ Projects", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Seo title="Peerdweb: Project List" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Stack direction="row" justifyContent="space-between" spacing={4}>
                            <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
                                <Typography variant="h5" sx={{ mb: 2, color: '#6C737F' }}>
                                    Projects //
                                </Typography>
                                <Typography variant="h4" sx={{ mb: 2 }}>
                                    {organisation?.name ?? ''}
                                </Typography>
                            </Stack>
                            {/* <Stack alignItems="center" direction="row" spacing={3}>
                                <Button component={RouterLink} href={paths.assets.new} startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)} variant="contained">
                                    New Project?
                                </Button>
                            </Stack> */}
                        </Stack>
                        <Divider />
                        <ViewProjects />
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default ProjectListPage;
