import { FC, useCallback, useEffect } from 'react';
import { Card, CardContent, Stack, TextField, Typography, Unstable_Grid2 as Grid, Button } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import { OrganisationOwner } from './organisation-owner';
import { useFormik } from 'formik';
import { Organisation, UpdateOrganisationValues } from 'types/organisation/organisation';
import { UpdateOrganisationValidationSchema } from 'validation/organisation/organisation-validation';
import { deepCopy } from 'utils/deep-copy';
import toast from 'react-hot-toast';
import getDebugData from 'utils/cherry-pick-seq-data';

export const ManageOrganisation: FC = () => {
    const auth = useAuth();
    const { organisation, updateOrganisation, getSubscriptionLevel } = auth;
    const [log] = useLogger();

    const initialValues: UpdateOrganisationValues = {
        name: organisation?.name ?? ''
    };

    const updateOrganisationCall = useCallback(async (values: UpdateOrganisationValues) => {
        try {
            if (organisation) {
                let updated = deepCopy(organisation) as Organisation
                updated.name = values.name;
                await updateOrganisation(updated);
                log("Organisation details updated", "Information", getDebugData(auth));
                toast.success("Organisation details saved", { icon: "✅" });
                // reloadUser();
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: UpdateOrganisationValidationSchema,
        onSubmit: updateOrganisationCall
    });

    useEffect(() => { log("Organisation ___ View", "Debug", getDebugData(auth)); }, []);

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} md={2}>
                                <Typography variant="h6">
                                    Organisation
                                </Typography>
                            </Grid>

                            <Grid xs={12} md={6}>
                                <Stack spacing={3}>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField
                                            error={!!(formik?.touched.name && formik.errors.name)}
                                            helperText={formik?.touched.name && formik.errors.name}
                                            label="Name"
                                            required
                                            sx={{ flexGrow: 1 }}
                                            {...formik?.getFieldProps("name")} />
                                        <Button type='submit' variant='contained'>
                                            Save
                                        </Button>
                                    </Stack>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField
                                            label="Subscription Level"
                                            defaultValue={getSubscriptionLevel()}
                                            disabled
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField
                                            label="Credits"
                                            defaultValue={organisation?.credits}
                                            disabled
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <OrganisationOwner />
            </Stack >
        </form>
    );
};

ManageOrganisation.propTypes = {

};
