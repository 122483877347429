import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { sendEmailVerification, type User as FirebaseUser, applyActionCode } from '@firebase/auth';
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { firebaseApp } from 'libs/firebase';
import type { AuthUser, PeerdwebUser } from 'types/users/user';
import { Issuer } from 'utils/auth';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { Organisation } from 'types/organisation/organisation';
import { deepCopy } from 'utils/deep-copy';
import axios from "axios";
import { useLogger } from 'hooks/use-logger';
import getDebugData from 'utils/cherry-pick-seq-data';

const auth = getAuth(firebaseApp);

export interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    authUser: AuthUser | null;
    ip: string | null;
    peerdwebUser: PeerdwebUser | null;
    organisation: Organisation | null;
    organisations: Organisation[] | null;
    token: string | null;
}

enum ActionType {
    AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED'
}

type AuthStateChangedAction = {
    type: ActionType.AUTH_STATE_CHANGED;
    payload: {
        isAuthenticated: boolean;
        authUser: AuthUser | null;
        ip: string | null;
        peerdwebUser: PeerdwebUser | null;
        organisation: Organisation | null;
        organisations: Organisation[] | null;
        token: string | null;
    };
};

type Action = AuthStateChangedAction;

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    authUser: null,
    ip: null,
    peerdwebUser: null,
    organisation: null,
    organisations: null,
    token: null
};

const reducer = (state: State, action: Action): State => {
    if (action.type === 'AUTH_STATE_CHANGED') {
        const { isAuthenticated, authUser, peerdwebUser, organisation, organisations, ip, token } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            authUser,
            ip,
            peerdwebUser,
            organisation,
            organisations,
            token
        };
    }

    return state;
};

export interface AuthContextType extends State {
    issuer: Issuer.Firebase;
    createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    signInWithGoogle: () => Promise<any>;
    signOut: () => Promise<void>;
    reloadUser: () => Promise<void>;
    verifyEmail: (oobCode: string) => Promise<void>;
    updatePeerdwebUser: (user: PeerdwebUser) => Promise<any>;
    switchOrganisation: (organisationId: string) => Promise<void>;
    getSubscriptionLevel: () => string;
    updateOrganisation: (organisation: Organisation) => Promise<any>;
}

export const AuthContext = createContext<AuthContextType>({
    ...initialState,
    issuer: Issuer.Firebase,
    createUserWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
    reloadUser: () => Promise.resolve(),
    verifyEmail: () => Promise.resolve(),
    updatePeerdwebUser: () => Promise.resolve(),
    switchOrganisation: () => Promise.resolve(),
    getSubscriptionLevel: () => '',
    updateOrganisation: () => Promise.resolve(),
});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const [log] = useLogger();
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const DEFAULT_AVATAR = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'

    //#region User functions

    const getOrCreatePeerdwebUser = useCallback(async (firebaseUser: FirebaseUser): Promise<PeerdwebUser> => {
        const peerdwebService = new PeerdwebService();

        try {
            const response = await peerdwebService.getUserByFirebaseUid(firebaseUser.uid)
            log("User retrieved", "Debug", { firebaseUser, data: response.data, ...getDebugData(state) });
            return response.data;

        } catch {
            return await createPeerdwebUser(firebaseUser);
        }
    }, []);


    const createPeerdwebUser = useCallback(async (firebaseUser: FirebaseUser): Promise<PeerdwebUser> => {
        const peerdwebService = new PeerdwebService();

        try {
            await peerdwebService.createUser({
                displayName: firebaseUser.displayName ?? 'Peer#' + Math.floor(Math.random() * (999 - 100 + 1) + 100).toString(),
                email: firebaseUser.email ?? 'unknown@peerdweb.io',
                firebaseUid: firebaseUser.uid,
                imageUrl: DEFAULT_AVATAR,
                createdAt: firebaseUser.metadata.creationTime,
                lastLoggedInAt: firebaseUser.metadata.lastSignInTime
            });
            const response = await peerdwebService.getUserByFirebaseUid(firebaseUser.uid);
            log("User created", "Debug", { firebaseUser, data: response.data, ...getDebugData(state) });
            log("User created", "Information", { uid: firebaseUser.uid });
            return response.data;

        } catch (error) {
            const code = 'auth/user-not-found';
            log("User creation failed", "Error", { uid: firebaseUser.uid, error, code });
            throw { code };
        }
    }, []);


    const updatePeerdwebUser = useCallback(async (user: PeerdwebUser): Promise<PeerdwebUser> => {
        const peerdwebService = new PeerdwebService();

        try {
            const response = await peerdwebService.updateUser(user)
            return response.data;

        } catch (error) {
            const code = 'auth/user-not-found';
            log("User update failed", "Error", { id: user._id, error, code });
            throw { code };
        }
    }, []);

    //#endregion



    // #region Organisation functions

    const getOrCreateOrganisation = useCallback(async (peerdwebUser: PeerdwebUser): Promise<Organisation> => {
        const peerdwebService = new PeerdwebService();

        try {
            if (!peerdwebUser._id) {
                const code = 'auth/default-organisation-not-found';
                log("Organisation not found, missing user id", "Error", { uid: peerdwebUser.firebaseUid, code });
                throw { code };
            }
            const response = await peerdwebService.getDefaultOrganisationByUserId(peerdwebUser._id);
            return response.data;

        } catch {
            log("Organisation not found, creating new", "Warning", { uid: peerdwebUser.firebaseUid });
            const response = await createOrganisationFromPeerdwebUser(peerdwebUser);
            return response.data;
        }
    }, []);


    const getOrganisations = useCallback(async (peerdwebUser: PeerdwebUser): Promise<Organisation[]> => {
        const peerdwebService = new PeerdwebService();

        try {
            if (!peerdwebUser._id) {
                const code = 'auth/default-organisation-not-found';
                log("Organisation not found, missing user id", "Error", { uid: peerdwebUser.firebaseUid, code });
                throw { code };
            }
            const response = await peerdwebService.getOrganisations(peerdwebUser._id);
            return response.data.organisations;

        } catch {
            return [];
        }
    }, []);


    const createOrganisationFromPeerdwebUser = useCallback(async (peerdwebUser: PeerdwebUser) => {
        const peerdwebService = new PeerdwebService();

        try {
            await peerdwebService.createOrganisationFromPeerdwebUser(peerdwebUser);
            if (!peerdwebUser._id) {
                const code = 'auth/default-organisation-not-found';
                log("Cannot create organisation, missing user id", "Error", { uid: peerdwebUser.firebaseUid, code });
                throw { code };
            }
            return await peerdwebService.getDefaultOrganisationByUserId(peerdwebUser._id);

        } catch (error) {
            const code = 'auth/organisation-cannot-be-created';
            log("Organisation creation failed", "Error", { uid: peerdwebUser.firebaseUid, error, code });
            throw { code };
        }
    }, []);


    const getSubscriptionLevel = useCallback(() => {
        return state.organisation?.subscriptionLevel.toLocaleUpperCase() || 'FREE';
    }, [state.organisation]);


    const switchOrganisation = useCallback(async (organisationId: string) => {
        const peerdwebService = new PeerdwebService();

        try {
            const response = await peerdwebService.getOrganisationById(organisationId);
            let updated = deepCopy(state.peerdwebUser) as PeerdwebUser
            updated.defaultOrganisationId = organisationId;
            await updatePeerdwebUser(updated);
            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: true,
                    authUser: state.authUser,
                    peerdwebUser: updated,
                    organisation: response.data,
                    organisations: state.organisations,
                    ip: state.ip,
                    token: state.token
                }
            });

        } catch (error) {
            const code = 'auth/organisation-not-found';
            log("Organisation not found", "Error", { id: organisationId, error, code });
            throw { code };
        }
    }, [state]);

    const updateOrganisation = useCallback(async (organisation: Organisation): Promise<Organisation> => {
        const peerdwebService = new PeerdwebService();

        try {
            const response = await peerdwebService.updateOrganisation(organisation)
            return response.data;
        } catch (error) {
            const code = 'auth/organisation-not-found';
            log("Organisation update failed", "Error", { id: organisation._id, error, code });
            throw { code };
        }
    }, []);

    // #endregion



    const handleAuthStateChanged = useCallback(async (firebaseUser: FirebaseUser | null) => {

        if (auth && firebaseUser && firebaseUser.emailVerified) {
            const token = await firebaseUser.getIdToken(true);
            const peerdwebUser = await getOrCreatePeerdwebUser(firebaseUser);
            const organisation = await getOrCreateOrganisation(peerdwebUser);
            const organisations = await getOrganisations(peerdwebUser);

            if (!peerdwebUser.createdAt)
                peerdwebUser.createdAt = firebaseUser.metadata.creationTime ?? new Date().toISOString();
            if (peerdwebUser.imageUrl === DEFAULT_AVATAR && firebaseUser.photoURL && firebaseUser.photoURL.length > 0)
                peerdwebUser.imageUrl = firebaseUser.photoURL;
            if (!peerdwebUser.defaultOrganisationId && organisation._id)
                peerdwebUser.defaultOrganisationId = organisation._id;

            peerdwebUser.lastLoggedInAt = new Date().toISOString();

            //can get further data later, e.g.: https://geolocation-db.com/jsonp/81.99.225.127
            const res = await axios.get("https://api.ipify.org/?format=json");
            const ip = res.data.ip;
            peerdwebUser.ip = ip;

            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: true,
                    authUser: {
                        id: firebaseUser.uid,
                        avatar: firebaseUser.photoURL || undefined,
                        email: firebaseUser.email || 'Unknown',
                        name: firebaseUser.displayName || 'Unknown',
                        lastLoginAt: firebaseUser.metadata.lastSignInTime,
                    },
                    peerdwebUser,
                    organisation,
                    organisations,
                    ip,
                    token
                }
            });
            await updatePeerdwebUser(peerdwebUser);


        } else {
            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: false,
                    authUser: null,
                    peerdwebUser: null,
                    organisation: null,
                    organisations: null,
                    ip: null,
                    token: null
                }
            });
        }
    }, [dispatch]);

    useEffect(() => onAuthStateChanged(auth, handleAuthStateChanged), []);


    // #region Firebase function

    const _signInWithEmailAndPassword = useCallback(async (email: string, password: string): Promise<void> => {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
            const code = 'peerdweb/email-not-verified';
            sendEmailVerification(userCredential.user);
            log("Email not verified", "Warning", { code, ...getDebugData(state) });
            throw { code };
        }
        log("User signed in via Email and Password", "Information", getDebugData(state));
    }, []);

    const signInWithGoogle = useCallback(async (): Promise<void> => {
        log("User signing in via Google", "Information", getDebugData(state));
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    }, []);

    const _createUserWithEmailAndPassword = useCallback(async (email: string, password: string): Promise<void> => {
        const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
        log("User created via Email and Password", "Debug", { email, ...getDebugData(state) });
        log("User created via Email and Password", "Information");
        await sendEmailVerification(userCredentials.user);
    }, []);

    const _signOut = useCallback(async (): Promise<void> => {
        await signOut(auth);
    }, []);

    const _reloadUser = useCallback(async (): Promise<void> => {
        await handleAuthStateChanged(auth.currentUser);
    }, []);

    const _verifyEmail = useCallback(async (oobCode: string): Promise<void> => {
        await applyActionCode(auth, oobCode);
    }, []);

    // const refreshToken = useCallback(async (): Promise<any> => {
    //     return await auth.currentUser?.getIdToken(true);
    // }, []);

    // #endregion

    return (
        <AuthContext.Provider
            value={{
                ...state,
                issuer: Issuer.Firebase,
                createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
                signInWithEmailAndPassword: _signInWithEmailAndPassword,
                signInWithGoogle,
                signOut: _signOut,
                reloadUser: _reloadUser,
                verifyEmail: _verifyEmail,
                updatePeerdwebUser,
                switchOrganisation,
                getSubscriptionLevel,
                updateOrganisation,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
