import { State } from "contexts/firebase-context";

const getDebugData = (auth: State) => {
    // don't leak sensitive data in production
    if (process.env.NODE_ENV === 'production')
        return;
    else
        return {
            user: {
                email: auth?.authUser?.email,
                name: auth?.authUser?.name,
                id: auth?.authUser?.id,
            },
            organisation: {
                id: auth?.organisation?._id,
                name: auth?.organisation?.name,
                credits: auth?.organisation?.credits,
                subscriptionLevel: auth?.organisation?.subscriptionLevel,
            },
            session: auth?.token?.substring(0, 24),
        };
};

export default getDebugData;
