import { Autocomplete, Avatar, Box, Card, Container, Divider, MenuItem, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { Seo } from "components/seo";
import { usePageView } from "hooks/use-page-view";
import type { Page as PageType } from "types/page";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useMemo, useState } from "react";
import { DateRangeSearchRequest } from "types/analytics/date-range-search-request";
import { addDays, formatISO } from "date-fns";
import { ActivityLogsEnhancedTable } from "components/assets/smart-access/activity-logs-enhanced-table";
import { useSmartAccessActivityLogStore } from "hooks/smart-access/useSmartAccessActivityLogStore";
import { useAuth } from "hooks/use-auth";
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { PeerdwebUser } from "types/users/user";
import { toLowerCaseNoSpaces } from "utils/pretty-pretty";
import { useLogger } from "hooks/use-logger";
import getDebugData from "utils/cherry-pick-seq-data";

interface UserOption {
    firstLetter: string;
    user: PeerdwebUser;
}

const AnalyticsPage: PageType = () => {
    usePageView();

    const auth = useAuth();
    const { organisation } = auth;
    const [log] = useLogger();
    const users = organisation?.users ?? [];
    const userOptions: UserOption[] = users.map((user) => {
        const firstLetter = user.displayName[0].toUpperCase();

        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            user: user
        };
    }).sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));

    const searchActivityLogs = useSmartAccessActivityLogStore();

    const today = useMemo(() => new Date(), []);
    const defaultEndDate = useMemo(() => new Date(formatISO(today, { representation: 'date' })), [today]);

    const sevenDaysAgo = addDays(today, -365);
    const defaultStartDate = useMemo(() => new Date(formatISO(sevenDaysAgo, { representation: 'date' })), [sevenDaysAgo]);

    const [dateRange, setDateRange] = useState<DateRangeSearchRequest>({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });
    const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined);
    const [selectedUserName, setSelectedUserName] = useState<string>('All Users');

    useEffect(() => {
        const fetchData = async () => {
            const searchRequest = {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                userId: selectedUserId ?? undefined,
            };

            await searchActivityLogs.searchSmartAccessActivityLog(searchRequest);
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.startDate, dateRange.endDate, selectedUserId]);

    const handleUserChange = (_: React.SyntheticEvent, value: UserOption | null) => {
        setSelectedUserId(value?.user._id);
        setSelectedUserName(value?.user.displayName ?? 'All Users');
    };

    useEffect(() => { log("Page ___ Analytics", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Seo title="Peerdweb: Analytics" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Stack spacing={1}>
                            <Typography variant="h4">Analytics</Typography>
                        </Stack>

                        <Divider />

                        <Card>
                            <Stack alignItems="center" direction='row' spacing={3} sx={{ px: 4, pt: 3 }}>
                                <Typography variant="body1">
                                    Use the fields below to filter {organisation?.name}'s <strong>Smart Access</strong> logs by date range and user:
                                </Typography>
                            </Stack>
                            <Stack alignItems="center" direction='row' spacing={3} sx={{ px: 4, py: 3 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        label="Start"
                                        value={dateRange.startDate}
                                        onChange={(newValue) => {
                                            setDateRange({
                                                ...dateRange,
                                                startDate: newValue as Date,
                                            });
                                        }}
                                        disabled={searchActivityLogs.isLoading}
                                    />

                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        label="End"
                                        value={dateRange.endDate}
                                        onChange={(newValue) => {
                                            setDateRange({
                                                ...dateRange,
                                                endDate: newValue as Date,
                                            });
                                        }}
                                        disabled={searchActivityLogs.isLoading}
                                    />

                                    <Autocomplete
                                        id="user-select"
                                        sx={{ width: 300, mb: 1 }}
                                        options={userOptions}
                                        groupBy={(option) => option.firstLetter}
                                        getOptionLabel={(option) => option.user.displayName}
                                        renderInput={(params) => <TextField {...params} label="User" />}
                                        renderOption={(props, option) => {
                                            const { ...optionProps } = props;

                                            return (
                                                <MenuItem {...optionProps}>
                                                    <Stack alignItems="center" direction="row" spacing={1}>
                                                        <Avatar src={option.user.imageUrl} sx={{ height: 40, width: 40 }}>
                                                            <SvgIcon>
                                                                <User01Icon />
                                                            </SvgIcon>
                                                        </Avatar>
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                {option.user.displayName}
                                                            </Typography>
                                                            <Typography color="text.secondary" variant="body2">
                                                                {option.user.email}
                                                            </Typography>
                                                        </div>
                                                    </Stack>
                                                </MenuItem>
                                            );
                                        }}
                                        onChange={handleUserChange}
                                        disabled={searchActivityLogs.isLoading}
                                    />
                                </LocalizationProvider>

                            </Stack>
                        </Card>

                        <ActivityLogsEnhancedTable activityLogs={searchActivityLogs.state} csvFileName={`smart-access-logs-${toLowerCaseNoSpaces(selectedUserName)}-from-${dateRange.startDate.toISOString().split('T')[0]}-to-${dateRange.endDate.toISOString().split('T')[0]}.csv`} isLoading={searchActivityLogs.isLoading} title={`Smart Access Logs: ${selectedUserName}`} />
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default AnalyticsPage;