import { FC, useCallback, useEffect } from 'react';
import { Card, CardContent, Stack, Typography, Unstable_Grid2 as Grid, Button, Box, FormHelperText } from '@mui/material';
import { QuillEditor } from 'components/quill-editor';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import EnterprisePricingCard from './enterprise-pricing-card';
import { useFormik } from 'formik';
import { Organisation, UpdateSmartNdaValues } from 'types/organisation/organisation';
import { UpdateSmartNdaValidationSchema } from 'validation/organisation/organisation-validation';
import { deepCopy } from 'utils/deep-copy';
import toast from 'react-hot-toast';
import getDebugData from 'utils/cherry-pick-seq-data';

interface IProps {
    isEnterprise: boolean;
}

export const ManageSmartNda: FC<IProps> = ({ isEnterprise }) => {

    const auth = useAuth();
    const { organisation, updateOrganisation } = auth;
    const [log] = useLogger();

    const initialValues: UpdateSmartNdaValues = {
        obligationHtml: organisation?.obligationHtml ?? ''
    };

    const updateSmartNdaValues = useCallback(async (values: UpdateSmartNdaValues) => {
        try {
            let updated = deepCopy(organisation) as Organisation;
            updated.obligationHtml = values.obligationHtml;
            await updateOrganisation(updated);
            log("Smart NDA settings saved", "Information", getDebugData(auth));
            toast.success("Smart NDA settings saved", { icon: "✅" });
        } catch (error) {
            log("Error saving Smart NDA settings", "Error", { error, ...getDebugData(auth) });
            console.error(error);
        }
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: UpdateSmartNdaValidationSchema,
        onSubmit: updateSmartNdaValues
    });

    useEffect(() => { log("Organisation ___ Smart NDA", "Debug", { isEnterprise, ...getDebugData(auth) }); }, []);

    return (
        <Stack spacing={4}>
            {isEnterprise &&
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                    <Typography variant="h6">
                                        User Obligation
                                    </Typography>
                                </Grid>
                                <Grid xs={12} md={10}>
                                    <Stack spacing={3}>
                                        <QuillEditor
                                            onChange={(value: string): void => {
                                                formik.setFieldValue('obligationHtml', value);
                                            }}
                                            placeholder="Smart Access Obligation"
                                            sx={{ height: 170, backgroundColor: '#ffffff' }}
                                            value={formik.values.obligationHtml}
                                        />
                                        {!!(formik.touched.obligationHtml && formik.errors.obligationHtml) && (
                                            <Box sx={{ mt: 2 }}>
                                                <FormHelperText error>
                                                    {formik.errors.obligationHtml}
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Stack>
                                    <Button sx={{ mt: 2 }} type='submit' variant='contained'>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
            }

            {!isEnterprise &&
                <>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                    <Typography variant="h6">
                                        Smart NDA
                                    </Typography>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Typography variant="h5">
                                        Enterprise Subscription Plan only.
                                    </Typography>
                                    <br /><br />
                                    Our <strong>Enterprise</strong> plan offers expanded features to streamline your IP management including multiple user licenses, seamless integration to your secure storage, Smart Access, Smart NDAs, and comprehensive reporting dashboards
                                    <br /><br />
                                    The <strong>Enterprise</strong> plan empowers your team to collaborate more efficiently and take full advantage of Peerdweb's advanced capabilities. Upgrade today to unlock the full potential of your IP portfolio management.
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Stack spacing={3}>
                                        <EnterprisePricingCard />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            }
        </Stack >
    );
};

ManageSmartNda.propTypes = {

};
