import { FC, useEffect } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography, Unstable_Grid2 as Grid, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import EnterprisePricingCard from './enterprise-pricing-card';
import { Scrollbar } from 'components/scrollbar';
import { useLogger } from 'hooks/use-logger';
import getDebugData from 'utils/cherry-pick-seq-data';

interface IProps {
    isEnterprise: boolean;
}

export const ManageOrganisationUsers: FC<IProps> = ({ isEnterprise }) => {
    const auth = useAuth();
    const [log] = useLogger();
    const { organisation } = auth;

    useEffect(() => { log("Organisation Users ___ View", "Debug", getDebugData(auth)); }, []);

    return (
        <Stack spacing={4}>
            {isEnterprise &&
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} md={2}>
                                <Typography variant="h6">
                                    Users
                                </Typography>
                                <Typography variant="caption">
                                    {organisation?.seatCount} seats
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={10}>
                                <Stack spacing={3}>
                                    <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                                        <Table sx={{ minWidth: 500 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        User
                                                    </TableCell>
                                                    <TableCell>
                                                        Last Logged In
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {organisation?.users?.map((user, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                                <Avatar src={user.imageUrl} sx={{ height: 40, width: 40 }}>
                                                                    <SvgIcon>
                                                                        <User01Icon />
                                                                    </SvgIcon>
                                                                </Avatar>
                                                                <div>
                                                                    <Typography variant="subtitle2">
                                                                        {user.displayName}
                                                                    </Typography>
                                                                    <Typography color="text.secondary" variant="body2">
                                                                        <Link href={`mailto:${user.email}`}>{user.email}</Link>
                                                                    </Typography>
                                                                </div>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell>
                                                            {user.lastLoggedInAt && new Date(user.lastLoggedInAt).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Scrollbar>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }

            {!isEnterprise &&
                <>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                    <Typography variant="h6">
                                        Users
                                    </Typography>
                                    <Typography variant="caption">
                                        {organisation?.seatCount} seats
                                    </Typography>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Typography variant="h5">
                                        Enterprise Subscription Plan only.
                                    </Typography>
                                    <br /><br />
                                    Our <strong>Enterprise</strong> plan offers expanded features to streamline your IP management including multiple user licenses, seamless integration to your secure storage, Smart Access, Smart NDAs, and comprehensive reporting dashboards
                                    <br /><br />
                                    The <strong>Enterprise</strong> plan empowers your team to collaborate more efficiently and take full advantage of Peerdweb's advanced capabilities. Upgrade today to unlock the full potential of your IP portfolio management.

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Stack spacing={3}>
                                        <EnterprisePricingCard />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            }
        </Stack>
    );
};

