import { Box, Button, Container, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { Seo } from 'components/seo';
import { usePageView } from 'hooks/use-page-view';
import type { Page as PageType } from 'types/page';
import { RouterLink } from 'components/router-link';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { paths } from 'paths';
import { ViewAssets } from 'components/assets/asset-list/view-assets';
import { useEffect } from 'react';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import getDebugData from 'utils/cherry-pick-seq-data';

const AssetListTestPage: PageType = () => {
    usePageView();
    const auth = useAuth();
    const [log] = useLogger();

    useEffect(() => { log("Page ___ Asset List", "Debug", getDebugData(auth)); }, []);

    return (
        <>
            <Seo title="Peerdweb: Asset List" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Stack direction="row" justifyContent="space-between" spacing={4}>
                            <Stack spacing={1}>
                                <Typography variant="h4">
                                    Assets
                                </Typography>
                            </Stack>
                            <Stack alignItems="center" direction="row" spacing={3}>
                                <Button component={RouterLink} href={paths.assets.new} startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)} variant="contained">
                                    New Asset
                                </Button>
                            </Stack>
                        </Stack>
                        <Divider />

                        <ViewAssets />

                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default AssetListTestPage;
