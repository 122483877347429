import { FC, useCallback } from 'react';
import PropTypes from 'prop-types';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Box, Button, Card, CardContent, Stack, SvgIcon, TextField, Typography, Unstable_Grid2 as Grid, Divider } from '@mui/material';
import { UpdateUserValues } from '../../types/account/user';
import { UpdateUserDetailsValidationSchema } from 'validation/users/user-validation';
import { deepCopy } from 'utils/deep-copy';
import { PeerdwebUser } from 'types/users/user';
import toast from 'react-hot-toast';
import { useAuth } from 'hooks/use-auth';
import { useFormik } from 'formik';
import { useLogger } from 'hooks/use-logger';
import getDebugData from 'utils/cherry-pick-seq-data';

interface IProps {
    imageUrl: string;
    email: string;
    displayName: string;
    lastLoggedInAt?: string;
    createdAt?: string
}

export const ManageUser: FC<IProps> = ({ imageUrl, email, displayName, lastLoggedInAt, createdAt }) => {
    const auth = useAuth();
    const [log] = useLogger();
    const { peerdwebUser, reloadUser, updatePeerdwebUser } = auth;

    const initialValues: UpdateUserValues = {
        displayName: displayName,
        imageUrl: imageUrl
    };

    const updateUser = useCallback(async (values: UpdateUserValues) => {
        try {
            if (peerdwebUser) {
                let updated = deepCopy(peerdwebUser) as PeerdwebUser
                updated.displayName = values.displayName;
                updated.imageUrl = values.imageUrl;
                await updatePeerdwebUser(updated);
                log("User details updated", "Information", getDebugData(auth));
                toast.success("User details saved", { icon: "✅" });
                reloadUser();
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: UpdateUserDetailsValidationSchema,
        onSubmit: updateUser
    });


    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} md={2}>
                                <Typography variant="h6">
                                    Profile
                                </Typography>
                            </Grid>

                            <Grid xs={12} md={6}>
                                <Stack spacing={3}>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <Box sx={{ borderColor: 'neutral.300', borderRadius: '50%', borderStyle: 'dashed', borderWidth: 1, p: '4px' }}>
                                            <Box sx={{ borderRadius: '50%', height: '100%', width: '100%', position: 'relative' }}>
                                                <Avatar src={imageUrl} sx={{ height: 100, width: 100 }}>
                                                    <SvgIcon>
                                                        <User01Icon />
                                                    </SvgIcon>
                                                </Avatar>
                                            </Box>
                                        </Box>
                                    </Stack>

                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField
                                            error={!!(formik?.touched.displayName && formik.errors.displayName)}
                                            helperText={formik?.touched.displayName && formik.errors.displayName}
                                            label="Full Name"
                                            required
                                            sx={{ flexGrow: 1 }}
                                            {...formik?.getFieldProps("displayName")} />
                                        <Button type='submit' variant='contained'>
                                            Save
                                        </Button>
                                    </Stack>

                                    <Divider />

                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField
                                            label="Email Address"
                                            defaultValue={email}
                                            disabled
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>

                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <TextField
                                            label="Last Logged In"
                                            defaultValue={lastLoggedInAt && new Date(lastLoggedInAt).toUTCString()}
                                            disabled
                                            sx={{ flexGrow: 1, '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'dashed' } }}
                                        />
                                    </Stack>

                                    <Stack alignItems="center" direction={"row"} spacing={2}>
                                        <TextField
                                            defaultValue={createdAt && new Date(createdAt).toUTCString()}
                                            disabled
                                            label="Peer Since"
                                            sx={{ flexGrow: 1, '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'dashed' } }}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Stack >
        </form>
    );
};

ManageUser.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
};
