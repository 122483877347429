import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Divider, Grid, InputAdornment, Stack, SvgIcon, Tab, Tabs, TextField, Typography } from '@mui/material';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { useAuth } from 'hooks/use-auth';
import { SmartAccess } from 'types/smart-access/smart-access';
import { useLogger } from 'hooks/use-logger';
import { SmartAccessOrganisationUsers } from './smart-access-organisation-users';
import Mail01Icon from '@untitled-ui/icons-react/build/esm/Mail01';
import getDebugData from 'utils/cherry-pick-seq-data';

interface IProps {
    assetId?: string,
    headline?: string
}

export const SmartAccessControlPanel: FC<IProps> = ({ assetId, headline }) => {
    const auth = useAuth();
    const { peerdwebUser, organisation } = auth;
    const [currentTab, setCurrentTab] = useState<string>('organisation');
    const peerdwebService = new PeerdwebService();
    const [log] = useLogger();

    const [smartAccessList, setSmartAccessList] = useState<{ [userId: string]: SmartAccess | null | undefined }>({});

    const getUserAccess = async (userId?: string) => {
        if (!userId || !assetId) return;

        try {
            const response = await peerdwebService.getSmartAccessByAssetAndUser(assetId, userId);
            setSmartAccessList((prevResults) => ({
                ...prevResults,
                [userId]: response.data?.smartAccess,
            }));
        } catch (error) {
            console.error(error);
            setSmartAccessList((prevResults) => ({
                ...prevResults,
                [userId]: null, // Handle the error and set a default value
            }));
        }
    };

    const invite = async (userId?: string) => {
        if (!userId || !assetId || !peerdwebUser || peerdwebUser._id === undefined || !organisation || organisation._id === undefined) return;

        try {
            await peerdwebService.inviteSmartAccess(assetId, userId, peerdwebUser._id, organisation.obligationHtml);
            refreshAccessList();

        } catch (error) {
            console.error(error);
        }
    };

    const revoke = async (userId?: string) => {
        if (!userId || !peerdwebUser || peerdwebUser._id === undefined) return;

        try {
            await peerdwebService.revokeSmartAccess(smartAccessList[userId]?._id as string, peerdwebUser._id);
            refreshAccessList();

        } catch (error) {
            console.error(error);
        }
    };

    const refreshAccessList = () => {
        // teamPeers.users.forEach((peer) => getUserAccess(peer._id));
        organisation?.users.forEach((peer) => getUserAccess(peer._id));
    }

    useEffect(() => {
        organisation?.users.forEach((peer) => getUserAccess(peer._id));
    }, [organisation?.users, assetId]);

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    }, []);



    useEffect(() => { log("Smart Access Control ___ View", "Debug", { assetId, headline, ...getDebugData(auth) }); }, []);

    return (
        <>
            {/* <pre>
                Asset ID: {JSON.stringify(assetId)}
            </pre> */}

            <Box component="main" sx={{ flexGrow: 1, pb: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <div>
                            <Tabs indicatorColor="primary" allowScrollButtonsMobile onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                                <Tab key='organisation' label='Organisation Users' value='organisation' />
                                <Tab key='other' label='Other Users' value='other' />
                            </Tabs>
                            <Divider />
                        </div>

                        {currentTab === 'organisation' &&
                            <SmartAccessOrganisationUsers revoke={revoke} invite={invite} smartAccessList={smartAccessList} />
                        }

                        {currentTab === 'other' &&
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={4}>
                                            <Stack spacing={1} sx={{ p: 2 }}>
                                                <Typography variant="h6">
                                                    Smart Access
                                                </Typography>
                                                <Typography color="text.secondary" variant="body2">
                                                    Share with users outside your organisation
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12} md={8}>
                                            <Stack alignItems="center" direction="row" spacing={3} sx={{ p: 2 }}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon><Mail01Icon /></SvgIcon>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    label="Email address"
                                                    name="email"
                                                    sx={{ flexGrow: 1 }}
                                                    type="email"
                                                    value=""
                                                    disabled
                                                />
                                                <Button variant="contained" disabled>
                                                    Share Smart Access
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    </Stack>
                </Container>
            </Box>


        </>
    );
};
